<!-- was class="defaultDashboardLeadDevWrapper" -->
<div class="selectedDashboard">
    <app-layout header="Defaultdashboard.Label.LeadstateDevelopment" [subheader]="selectedReport?.subHeader">
        <div class="selectedDashboard__headerNav">
            <app-main-button (onClick)="dashboardcoreService.backToMainDashboards()" label="General._.Back" type="small"></app-main-button>
       </div> 
        <div class="selectedDashboard__innerBorderLayer">
            <div class="selectedDashboard__nav">
                <div class="selectedDashboard__filterMobile">
                    <app-accordion title="DefaultDashboard.Label.Filters" [isOpen]="true">
                        <div class="defaultDashboardLeadDevToolbar">
                            <app-date-input [calendarOnly]="true" label="OptInOptOut.Label.StartDate" [(ngModel)]="startDate" (dateChange)="getDashboardDataArray(dashboardId)"></app-date-input>	
                            <app-date-input [calendarOnly]="true" label="OptInOptOut.Label.EndDate" [(ngModel)]="endDate" (dateChange)="getDashboardDataArray(dashboardId)"></app-date-input>	
                            <app-select-input label="Account.Label.PoolId" [(ngModel)]="poolId" [options]="pools" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                            <app-select-input label="Account.Label.Leadstate" [(ngModel)]="leadstateId" [options]="leadstates" display="defaultName" bind="id" subArray="lookupTable" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                            <app-select-input *ngIf="
                                userService.hasPermission('CanUseNonSelfServiceNaviagionItem') && applicationInfoService.campaingnModeId == null
                            " 
                                label="Task.Label.Campaign" [(ngModel)]="campaignId" [options]="campaigns" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                            <app-select-input label="Task.Label.ResultUser" [(ngModel)]="userId" [options]="users" display="userName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                        </div>
                    </app-accordion>
                </div>

                <div class="selectedDashboard__filter">
                    <app-date-input [calendarOnly]="true" label="OptInOptOut.Label.StartDate" [(ngModel)]="startDate"
                        (dateChange)="getDashboardDataArray(dashboardId)"></app-date-input>
                    <app-date-input [calendarOnly]="true" label="OptInOptOut.Label.EndDate" [(ngModel)]="endDate"
                        (dateChange)="getDashboardDataArray(dashboardId)"></app-date-input>
                    <app-select-input label="Account.Label.PoolId" [(ngModel)]="poolId" [options]="pools" display="defaultName"
                        bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                    <app-select-input label="Account.Label.Leadstate" [(ngModel)]="leadstateId" [options]="leadstates"
                        display="defaultName" bind="id" subArray="lookupTable"
                        (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                    <app-select-input *ngIf="userService.hasPermission('CanUseNonSelfServiceNaviagionItem')"
                        label="Task.Label.Campaign" [(ngModel)]="campaignId" [options]="campaigns" display="defaultName" bind="id"
                        (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                    <app-select-input label="Task.Label.ResultUser" [(ngModel)]="userId" [options]="users" display="userName"
                        bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
                </div>
            </div>

            <div *ngIf="loadingData == true">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
            </div>

            <div class="selectedDashboard__content">
                <div *ngIf="loadingData == false" class="defaultDashboardLeadDevTableContainer">
                    <div class="defaultDashboardLeadDevBody">
                        <div class="defaultDashboardLeadDevBody__fixed">
                            <div class="defaultDashboardLeadDevHeader">
                                <div class="defaultDashboardLeadDevRowname">
                                    {{ 'LeadstateDevelopment.Label.FromTo' | texttransform }}
                                </div>
                            </div>
                            <div *ngFor="let tableRow of leadstates" class="everleadTableRow defaultDashboardLeadTableRow" [ngClass]="{
                                hideInDom: notInResultList(tableRow.lookupTable.id)
                            }">
                                <div class="defaultDashboardLeadDevRowname" 
                                    [tippy]="commonService.getTranslationValueFromArray(tableRow.lookupTable ) | texttransform">                                
                                    {{ commonService.getTranslationValueFromArray(tableRow.lookupTable ) | texttransform }}
                                    <!-- {{ getLeadStateName(tableRow) | texttransform }} -->
                                </div>    
                            </div>
                            <div class="defaultDashboardLeadTableRow defaultDashboardLeadDevRowname sumBorderTop">
                                {{ 'General.Label.Sum' | texttransform }}
                            </div>
                        </div>
                        <div class="defaultDashboardLeadDevBody__data">
                            <div class="defaultDashboardLeadDevHeader">
                                <div *ngFor="let tableCol of tableCols" class="tableRowItem tableRowItemHeaderItem"
                                    [tippy]="getLeadStateName(tableCol) | texttransform">                                
                                    {{ getLeadStateName(tableCol) | texttransform }}
                                </div>
                                <div class="tableRowItem tableRowItemHeaderItem"
                                    [tippy]="'General.Label.Sum' | texttransform">
                                    {{ 'General.Label.Sum' | texttransform }}
                                </div>
                            </div>        
                            <div *ngFor="let tableRow of leadstates" class="everleadTableRow defaultDashboardLeadTableRow" [ngClass]="{
                                hideInDom: notInResultList(tableRow.lookupTable.id)
                            }">
                                <div *ngFor="let tableCol of leadstates" class="tableRowItem tableRowItemValue" [ngClass]="{
                                    tableRowItemNoData: getAmount(tableRow.lookupTable.id, tableCol.lookupTable.id) == 0,
                                    tableRowItemSameData: checkSameData(tableRow.lookupTable.id, tableCol.lookupTable.id) 
                                }" (click)="showDevelopmentDetails(tableRow.lookupTable.id, tableCol.lookupTable.id)">
                                    {{ getAmount (tableRow.lookupTable.id, tableCol.lookupTable.id) }}                        
                                </div>
                                <div class="tableRowItem tableRowItemValue sumRight" [ngClass]="{
                                    tableRowItemNoData: getTotalRowAmount(tableRow.lookupTable.id) == 0
                                }">
                                    {{ getTotalRowAmount(tableRow.lookupTable.id) }}
                                </div>
                            </div>
                            <div class="everleadTableRow defaultDashboardLeadTableRow">
                                <div *ngFor="let tableCol of tableCols" class="tableRowItem tableRowItemValue sumBorderTop" [ngClass]="{
                                    tableRowItemNoData: getTotalColAmount(getLeadState(tableCol)?.lookupTable.id) == 0
                                }">                                    
                                    {{ getTotalColAmount (getLeadState(tableCol)?.lookupTable.id) }}
                                </div>
                                <div class="tableRowItem tableRowItemValue sumBorderTop" [ngClass]="{ tableRowItemNoData: getTotalAmount() == 0 }">                                    
                                    {{ getTotalAmount() }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

    
                <div *ngIf="developmentDetails != null" class="defaultDashboardLeadDevDetails">
                    <div class="closeDefaultDashboardLeadDevDetails">
                        <app-jj-icons [type]="'x'" [width]="20" [height]="20" (click)="developmentDetails = null;"></app-jj-icons>
                    </div>
                    <app-layout [header]="getDevelopmentDetailsHeader()">                    
                        <div class="defaultDashboardLeadDevDetailItem jjBold">
                            <div class="defaultDashboardLeadDevDetailItem__company jjBold">
                                {{ 'Contact.Label.CurrentLeadstate' | texttransform }}
                            </div>
                            <div class="defaultDashboardLeadDevDetailItem__company jjBold">
                                {{ 'Account.Label.Header' | texttransform }}
                            </div>
                            <div class="defaultDashboardLeadDevDetailItem__firstname">
                                {{ 'Contact.Label.FirstName' | texttransform }}
                            </div>
                            <div class="defaultDashboardLeadDevDetailItem__lastname">
                                {{ 'Contact.Label.LastName' | texttransform }}
                            </div>
                            <div class="defaultDashboardLeadDevDetailItem__date">
                                {{ 'Task.Label.ResultDate' | texttransform }}
                            </div>
                            <div class="defaultDashboardLeadDevDetailItem__controls"></div>
                        </div>
                        <div class="contentInner">
                            <div *ngFor="let dataItem of developmentDetails.data" 
                                class="defaultDashboardLeadDevDetailItem everleadTableRow">
                                <!-- <div class="defaultDashboardLeadDevDetailItem__icon">
    
                                </div> -->
                                <div class="defaultDashboardLeadDevDetailItem__company">
                                    {{ dataItem.lead_state }}
                                </div>
                                <div class="defaultDashboardLeadDevDetailItem__company">
                                    {{ dataItem.company }}
                                </div>
                                <div class="defaultDashboardLeadDevDetailItem__firstname">
                                    {{ dataItem.first_name }}
                                </div>
                                <div class="defaultDashboardLeadDevDetailItem__lastname">
                                    {{ dataItem.last_name }}
                                </div>
                                <div class="defaultDashboardLeadDevDetailItem__date">
                                    {{ dataItem.date | date: 'dd.MM.yyyy' }}
                                </div>
                                
                                <div class="defaultDashboardLeadDevDetailItem__controls">
                                    <app-jj-icons [type]="'eye'" [width]="20" [height]="20"
                                        (click)="loadContact(dataItem)"></app-jj-icons>
                                </div>
                            </div>
                        </div>
                    </app-layout>                
                </div>
            </div>
        </div>
    </app-layout>
</div>