import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashbdashboardcomponent-kpis',
  templateUrl: './dashbdashboardcomponent-kpis.component.html',
  styleUrls: ['./dashbdashboardcomponent-kpis.component.scss']
})
export class DashbdashboardcomponentKpisComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  kpis = null;
  loading = true;
  pieId = '';
  pieContactId = '';
  kpiList = [];
  @Input() showKPIs = true;


  // ! data for charts
/*   colors = [
    { background: '#ED5C73', font: 'white' },
    { background: '#59AA8E', font: 'white' },
    { background: '#F07E26', font: 'white' }
  ]; */
  chartOptionsAccounts = {
    color: ['#5465FF', '#5162F6','#788BFF'],
    data: [],
    type:'pie'

  }
  chartOptionsContacts={
    color: ['#5465FF', '#5162F6','#788BFF'],
    data: [],
    type: 'pie'

  }

  accountOptions=[];
  contactOptions= [];
  noAccountAvailable: boolean = false;
  noContactAvailable: boolean = false;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private eventService: EventService,
    public axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit(): void {
    this.getKPIs();
    this.pieId = this.commonService.createGuid();
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'selectedPoolChanged') { 
        this.getKPIs();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getKPIs() {
    this.loading = true;
    this.kpis = null;
    this.chartOptionsAccounts = { color: ['#5465FF', '#5162F6','#788BFF'], data: [], type:'pie' };
    this.chartOptionsContacts = { color: ['#5465FF', '#5162F6','#788BFF'], data: [], type: 'pie' };
    this.externaldatasourceService.executeExternalDataSource(823, [0, ''])
      .then(getKPIsResult => {
   /*    console.log('get KPIS', getKPIsResult) */
      if (getKPIsResult != null) {
        this.kpis = getKPIsResult[0];
        this.fillKpisForChart();
      } else {
        this.noAccountAvailable=true;
        this.noContactAvailable=true;
      }
      this.loading = false;
    });
  }

  fillKpisForChart() {
    this.noAccountAvailable=false;
    this.noContactAvailable=false;


    if(this.kpis?.accountProgressCounts?.length > 0 ){
      this.kpis.accountProgressCounts.sort((a, b) => a.type < b.type ? -1 : 1).forEach((accountProgressCount):any => {
/*         this.chartOptionsAccounts.data.push({ name: accountProgressCount.status , value: accountProgressCount.count,});
        this.chartOptionsAccounts.type="pie" */
        this.accountOptions.push({ name: accountProgressCount.status , value: accountProgressCount.count,})

      });

      this.accountOptions = this.createValues(this.accountOptions);
    } else {
      this.noAccountAvailable=true;
    }


    if(this.kpis?.contactProgressCounts?.length > 0){
      this.kpis.contactProgressCounts.sort((a, b) => a.type < b.type ? -1 : 1).forEach((contactProgressCount): any => {
        this.contactOptions.push({ name: contactProgressCount.status , value: contactProgressCount.count,})
    /*     this.chartOptionsContacts.data.push({ name: contactProgressCount.status , value: contactProgressCount.count,});
        this.chartOptionsContacts.type="pie" */
      });
      this.contactOptions = this.createValues(this.contactOptions);
    } else {
      this.noContactAvailable=true;
    }
  }


  createValues(data){
    let totalAmount= 0;
    let dataArray
    for (let i = 0; i < data.length; i++){
      totalAmount += data[i].value;
      if (data[i].value > 0) {
        dataArray = data.map((item): any => {
              return {
                label: this.axivasTranslateService.getTranslationTextForToken(item.name).concat(` (${item.value})`),
                value: this.getPercentage(item.value, totalAmount),
                percentage: this.getPercentage(item.value, totalAmount)
              }

        })
      }
    }
    return dataArray
  }

  getPercentage(value: number, totalAmount: number) {
    let returnValue = null;
    if (value != 0) {
      returnValue = ((value * 100) / totalAmount);
      returnValue = (Math.round(returnValue * 100) / 100).toFixed(1)
    } else {
      returnValue = 0;
    }
    return returnValue;
  }
}
